import { useState, useEffect, useMemo } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Amplify, Auth } from 'aws-amplify';
import amplifyconfig from '../amplifyconfiguration.json';
import AwsUtils from 'components/awsUtils';
import { setAWSVidVariable } from 'App';

// Example state to county mapping (simplified)
const stateToCounties = {
  Texas: ["Harris", "Dallas", "Tarrant"],
  California: ["Los Angeles", "San Diego", "San Francisco"],
  Florida: ["Miami-Dade", "Broward", "Palm Beach"]
};

const UserRole = ({ isOpen, onClose }) => {
  Amplify.configure(amplifyconfig);
  const [selectedRole, setSelectedRole] = useState('');
  const [state, setState] = useState('Texas');
  const [county, setCounty] = useState('NA');
  const [displayName, setDisplayName] = useState('');
  const { user, signOut } = useAuthenticator((context) => [context.user, context.signOut]);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const userRole = useMemo(() => {
    return user?.signInUserSession?.idToken?.payload['cognito:groups'] || [];
  },[])
  const [group, setGroup] = useState(userRole);
  const awsUtils = new AwsUtils({
    setGroup: setGroup
  });
  const userID = user?.signInUserSession?.idToken?.payload['cognito:username'];
  const userNickname = user?.signInUserSession?.idToken?.payload['nickname'] || "User Account";

  useEffect(() => {
    setGroup(userRole); // Update group whenever userRole changes
  }, [userRole]);

  useEffect(() => {
    if (isOpen) {
      setSelectedRole(group && group.length > 0 ? group[0] : ''); // Reset selectedRole to current role when modal opens
      setCounty(''); // Default to first county of the state
    }
  }, [isOpen, state, group]);

  if (!isOpen) return null;

  const handleSave = async () => {
    if (selectedRole === "") {
      onClose();
      return;
    }

    if (selectedRole === "stakeholder") {
      setAWSVidVariable(false);
    } else {
      setAWSVidVariable(true);
    }

    const meta_data = ['admin', 'stakeholder', 'researcher'].filter(item => item !== selectedRole);
    await awsUtils.addUserToGroup(userID, selectedRole);
    await Promise.all(meta_data.map(item => awsUtils.removeUserFromGroup(userID, item)));
    await awsUtils.refreshUserSession();

    // Re-fetch the user session data to update UI immediately
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then(freshUser => {
        console.log('User session refreshed:', freshUser);
        setGroup(freshUser.signInUserSession.idToken.payload['cognito:groups']);
      })
      .catch(err => {
        console.error('Error refreshing user session:', err);
      });

    onClose();

    // Show logging out message and log out the user
    setIsLoggingOut(true);
    setTimeout(() => {
      signOut();
    }, 1000); // Adjust the timeout as needed
  };

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: 999
    }}>
      <div style={{
        padding: '30px',
        width: '400px',
        backgroundColor: '#f4f4f9',
        borderRadius: '10px',
        boxShadow: '0 4px 16px rgba(0,0,0,0.2)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
      }}>
        <h1 style={{ color: '#333', marginBottom: '0px', fontSize: '25px' }}>Update Profile <div style={{ fontSize: '12px', paddingLeft: '148px' }}>Beta Version</div></h1>
        <p style={{ alignSelf: 'start', marginBottom: '0px' }}><b>State</b></p>
        <select
          disabled={true}
          value={state}
          onChange={(e) => { setState(e.target.value); }}
          style={{
            width: '100%',
            padding: '10px',
            marginBottom: '10px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            cursor: 'pointer'
          }}
        >
          {Object.keys(stateToCounties).map(state => (
            <option key={state} value={state}>{state}</option>
          ))}
        </select>

        <p style={{ alignSelf: 'start', marginBottom: '0px' }}><b>County</b></p>
        <select
          disabled={true}
          value={''}
          onChange={(e) => { setCounty(e.target.value); }}
          style={{
            width: '100%',
            padding: '10px',
            marginBottom: '20px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            cursor: 'pointer'
          }}
        >
          {stateToCounties[state].map(cnty => (
            <option key={cnty} value={cnty}>{cnty}</option>
          ))}
        </select>

        <p style={{ alignSelf: 'start', marginBottom: '0px' }}><b>Display Name</b></p>
        <input
          type="text"
          placeholder="Display Name"
          value={userNickname}
          onChange={(e) => setDisplayName(e.target.value)}
          style={{
            width: '100%',
            padding: '10px',
            marginBottom: '20px',
            borderRadius: '5px',
            border: '1px solid #ccc'
          }}
          disabled={true}
        />

        <hr style={{ width: '100%', margin: '10px 0' }} />
        <h3 style={{ textAlign: 'center', marginBottom: '5px', marginTop: '0px' }}>Superuser Settings</h3>
        <p style={{ marginBottom: '5px', alignSelf: 'start' }}><b>User Type*</b></p>
        <p style={{ color: 'red', alignSelf: 'start', marginBottom: '10px', marginTop: '0px', fontSize: '12px' }}>You will be logged out after changing the role.</p>
        <div style={{ marginBottom: '10px', alignSelf: 'start' }}>
          <label>
            <input
              type="radio"
              value="admin"
              checked={selectedRole === 'admin'}
              onChange={(e) => setSelectedRole(e.target.value)}
              style={{ marginRight: '10px' }}
            />
            Admin
          </label>
        </div>
        <div style={{ marginBottom: '10px', alignSelf: 'start' }}>
          <label>
            <input
              type="radio"
              value="stakeholder"
              checked={selectedRole === 'stakeholder'}
              onChange={(e) => setSelectedRole(e.target.value)}
              style={{ marginRight: '10px' }}
            />
            Stakeholder
          </label>
        </div>
        <div style={{ marginBottom: '10px', alignSelf: 'start' }}>
          <label>
            <input
              type="radio"
              value="researcher"
              checked={selectedRole === 'researcher'}
              onChange={(e) => setSelectedRole(e.target.value)}
              style={{ marginRight: '10px' }}
            />
            Researcher
          </label>
        </div>

        <button
          onClick={handleSave}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            color: 'white',
            backgroundColor: '#0c3678', // Primary color
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            width: '75%',
            height: '43px'
          }}
        >
          Save
        </button>
        <button
          onClick={onClose}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            color: 'white',
            backgroundColor: '#aaaaaa', // Secondary color
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            width: '75%',
            marginTop: '8px',
            height: '43px'
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default UserRole;
